<template>
  <sheet depressed dense light outlined>
    <v-row align="center">
      <v-col cols="12" sm="4" lg="2" xl="2">
        <v-switch
          :label="$t('contingency')"
          v-model="obInvoice.is_contingency"
        ></v-switch>
      </v-col>

      <v-col cols="12" sm="8" lg="6">
        <sheet dense>
          <v-row>
            <v-col cols="12" sm="4" xl="3">
              <form-field-text
                label="serie"
                :readonly="!obInvoice.is_contingency"
                :required="obInvoice.is_contingency"
                v-model="obInvoice.order_serial"
              />
            </v-col>
            <v-col cols="12" sm="8" xl="9">
              <form-field-text
                label="number"
                :readonly="!obInvoice.is_contingency"
                :required="obInvoice.is_contingency"
                v-model="obInvoice.order_number"
              />
            </v-col>
          </v-row>
        </sheet>
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="4"
        :xl="obInvoice.is_cash ? 4 : 2"
        :offset-sm="obInvoice.is_cash ? 6 : 0"
        offset-lg="0"
      >
        <form-field-date-picker v-model="createdAt" required />
      </v-col>

      <v-col
        cols="12"
        sm="6"
        lg="4"
        xl="2"
        offset-lg="8"
        offset-xl="0"
        v-if="!obInvoice.is_cash"
      >
        <form-field-date-picker v-model="dueDateAt" label="due.date" required />
      </v-col>
    </v-row>
  </sheet>
</template>

<script lang="ts">
import { Mixins, Component } from "vue-property-decorator";
import InvoiceMixin from "@/modules/invoices/mixins/InvoiceMixin";
import FormFieldDatePicker from "@/components/form/fields/DatePicker.vue";

@Component({
  components: { FormFieldDatePicker },
})
export default class InvoiceDates extends Mixins(InvoiceMixin) {
  get createdAt() {
    const obMoment = this.obInvoice
      ? this.$dayjs(this.obInvoice.created_at)
      : this.$dayjs();

    return obMoment.format("YYYY-MM-DD");
  }

  set createdAt(sValue: string) {
    this.obInvoice.set("created_at", sValue);
  }

  get dueDateAt() {
    const obMoment =
      this.obInvoice && this.obInvoice.due_date
        ? this.$dayjs(this.obInvoice.due_date)
        : this.$dayjs();

    return obMoment.format("YYYY-MM-DD");
  }

  set dueDateAt(sValue: string) {
    this.obInvoice.set("due_date", sValue);
  }
}
</script>
